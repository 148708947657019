import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../../components/Layouts/mainPage"
import { NewHeader } from "../../../components/Headers/NewHeader"
import { TopCta } from "../../../components/TopCta"
import { Banner } from "../../../components/Banners/BlackFridayHalva"
import { HowInstallmentWorks } from "../../../components/HowInstallmentWorks"
import { FormPKW } from "../../../components/FormPKW"
import { NewCalculator } from "../../../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../../../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../../../components/FourAdvantagesPoints"
import { OtherCards } from "../../../components/OtherCards"
import { BottomCta, RefinSeoBgImg } from "../../../components/BottomCta"
import { Partners } from "../../../components/PartnersNew"
import { Reviews } from "../../../components/Reviews"
import { Questions } from "../../../components/Questions"
import { NewFooter } from "../../../components/NewFooter"

import { getPageData } from "../../../helpers/getPageData"

import { PageData } from "../../../interfaces/pageProps"
import { QUESTIONS_FOR_INDEX_PKW } from "../../../components/Questions/helpers"
import { useHybridBanner } from "../../../hooks/useHybridBanner"
import { useObserver } from "../../../hooks/useObserver"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerTitle = `Чёрная пятница с&nbsp;Халвой 24 месяца рассрочки`

const bannerDescription = `
— До 17% на остаток по карте
<br />
— Кэшбэк до 10%
<br />— Обслуживание 0 ₽
`

const subTitlePartners =
  "Совершайте покупки в любых магазинах-партнерах и получайте 24 месяца рассрочки"

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const isVisibleTopCta = useObserver(["topCta"])

  const { ligal, noIndex } = getPageData(data)

  const banner = useHybridBanner(
    Banner as any,
    { title: bannerTitle, desc: bannerDescription, orderNum: "2" },
    { orderNum: "2", variantStyle: "blackFridayPKW" },
    { orderNum: "2", isDynamicText: true },
    data.allDynamicBanner
  )

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader
        redLogoWithSovcombank
        showHint={false}
        hasCTA
        noRightSection={isVisibleTopCta}
        withButton={!isVisibleTopCta}
        isVisibleBlockTopCta={isVisibleTopCta}
      />
      <TopCta orderNum="1" countDownTime={countDownTime} />
      <div>{banner}</div>
      <FourAdvantagesPoints
        withTitle
        indent
        variant="blackFridayHalva"
        title="Преимущества с Халвой:"
        orderNum="3"
      />
      <FormPKW
        progressBar
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="4"
        onlyPKWPage
        isGreenApprove
      />
      <HowInstallmentWorks
        headTitle="Как получить рассрочку на&nbsp;24&nbsp;месяца"
        variant="blackFridayHalva"
        orderNum="5"
      />
      <NewCalculator additionalEventInDataLayer orderNum="6" />
      <OtherCards orderNum="7" />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="8"
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="9"
        hasSubtitle
        variant="pkwItemsNewHint"
        bottomBlock={false}
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="10" />
      <Reviews additionalEventInDataLayer orderNum="11" />
      <Questions questionList={QUESTIONS_FOR_INDEX_PKW} additionalEventInDataLayer orderNum="12" />
      <NewFooter ligal={ligal} orderNum="13" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/black-friday-halva-pkw/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
